import React from 'react'
import './Hero.css'

import hero_image from '../../assets/hero_image.png'
import hero_image_back from '../../assets/hero_image_back.png'
import Heart from '../../assets/heart.png'
import calories from '../../assets/calories.png'
import Header from './Header/Header'
import {motion} from 'framer-motion' 
import NumberCounter from 'number-counter'
const Hero = () => {
    const transition = {type:'spring',duration:3};
    const mobile = window.innerWidth<=768? true: false;
  return (
    <div className='hero' id='home'> 

    <div className="blur hero-blur"></div>
     <div className="left-h">
        <Header/>
        <div className="best-add">
            <motion.div 
            initial={{left:mobile?"178px":"238px"}}
            whileInView={{left:"8px"}}
            transition={{...transition, type:"tween"}}
            ></motion.div>
            <span>the best fitness club in the town</span>
        </div>
        <div className="hero-text">
            <div>
                <span className='storke-text'>shape </span>
                <span>your</span>
            </div>
            <div>
                <span>ideal </span>
                <span>body</span>
            </div>
            <div>
                <span>
                in here we will help you to shape and bulid your ideal body
                </span>
            </div>
        </div>
        <div className="figure">
            <div>
                <span>
                    <NumberCounter end={140} start={100} delay='4' preFix='+' />
                </span>
                <span>expect coaching</span>
             </div>
            <div>
                <span>
                    <NumberCounter end={978} start={800} delay='4' preFix='+' />
                </span>
                <span>members joind</span>
             </div>
            <div>
                <span>
                <NumberCounter end={50} start={0} delay='4' preFix='+'/>
                </span>
                <span>Fitness programs</span>
             </div>
        </div>
        <div className="hero-button">
            <button className='btn'>Get Started</button>
            <button className='btn'>Learn More</button>
        </div>
     </div>
     <div className="Right-h">
        <button className="btn">Jion Now</button>
    
        <motion.div initial={{right:"-1rem"}}
            whileInView={{right:"4rem"}}
            transition={transition}
         className="heart-rate">
            
            <img src={Heart} alt="" />
            <span>Heart Rate</span>
             <span>116 bpm</span>
            
        </motion.div>
        <img src={hero_image} alt="" className='hero_img'/>
        <motion.img 
        initial={{right:"11rem"}}
        whileInView={{right:"20rem"}}
        transition={transition}
        src={hero_image_back}  alt="" className='hero_img_back' />

        <motion.div initial={{right:"32rem"}}
            whileInView={{right:"28rem"}}
            transition={transition}
        className="calories" >
            <img src={calories} alt="" />
            <div>
            <span>Calories Burned</span>
            <span>220 Kcal</span>
            </div>
        </motion.div>
     </div>
    </div>
  )
}   
export default Hero
